<template>
  <v-row :class="`summary-bar flex-nowrap mx-1 ${yMargin ? 'my-0 py-3' : 'my-0'} ${isSimplySummaryBar ? 'justify-center' : ''}`">
    <simple-summary-bar
      v-if="isSimplySummaryBar"
      class="py-3"
    />

    <template
      v-else-if="currentSummaries && Object.keys(currentSummaries).length"
    >
      <summary-card
        v-for="reportSummary in currentSummaries"
        :key="reportSummary.id"
        :summary-id="reportSummary.id"
      />
    </template>

    <summary-info-modal />
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('tools')

export default {
  name: 'SummaryCardBar',
  components: {
    SimpleSummaryBar: () => import('src/tools/components/summary/SimpleSummaryBar'),
    SummaryCard: () => import('src/tools/components/summary/SummaryCard'),
    SummaryInfoModal: () => import('src/tools/components/summary/SummaryInfoModal'),
  },
  computed: {
    ...mapGetters(['currentSummaries', 'isProgrammaticRoute', 'reportClass', 'showCurrentSavedFilters']),
    isSimplySummaryBar() {
      return this.showCurrentSavedFilters === 'simple' || this.isProgrammaticRoute
    },
    yMargin() {
      const shortSummaryBar = ['organization', 'pacing', 'person', 'project', 'yield/partner']
      return shortSummaryBar.indexOf(this.reportClass) !== -1
    },
  },
}
</script>

<style lang="scss" scoped>
.summary-bar {
  overflow-x: scroll;
}
</style>
