<template>
  <v-col class="summary px-3 py-0">
    <a
      href="javascript:void(0);"
      @click="activate"
    >
      <v-card
        :class="`summary-card cursor-pointer ${backgroundColor} ${styles.text}--text text-center justify-center pt-4 ${reportClass}-summary-card`"
      >
        <v-col>
          <v-row class="px-5 align-start flex-nowrap">
            <v-card-title
              class="summary-title text-uppercase caption pa-0 font-weight-bold text-left"
            >
              {{ title }}
            </v-card-title>

            <div>
              <v-icon
                v-if="!isLoading && isActive"
                class="close-button cursor-pointer"
                color="white"
                @click.stop="deactivate"
              >
                close
              </v-icon>

              <v-icon
                v-else-if="!isLoading && !isActive"
                class="close-button cursor-pointer"
                color="blue"
                @click.stop="showFilterInfo"
              >
                info
              </v-icon>

              <v-progress-circular
                v-if="isLoading"
                :size="21"
                class="loader"
                color="white"
                indeterminate
              />
            </div>
          </v-row>

          <v-row
            v-if="didFail"
            class="white--text overline px-5"
          >
            <v-icon>error_outline</v-icon>
            Error Loading
          </v-row>

          <v-row
            v-if="isLoading && !isActive"
            class="px-5 align-baseline"
          >
            <span
              :class="`${styles.value}--text headline pr-2 pt-7 pb-2`"
            >
              ...
            </span>
          </v-row>

          <v-row
            v-if="didSucceed"
            class="px-5"
          >
            <v-col class="py-2">
              <v-row class="align-baseline text-start pa-0">
                <div
                  :class="`headline ${styles.value}--text font-weight-bold pr-1`"
                >
                  {{ total }}
                </div>
              </v-row>

              <v-row
                v-if="itemLabel && itemsLength === 0 || !isActive"
                class="mt-2"
              >
                <span class="caption text-lowercase">
                  {{ itemLabel }}
                </span>
              </v-row>

              <v-row
                v-else-if="itemLabel && itemsLength >= 1 && isActive"
                class="align-baseline caption pa-0 mt-2"
              >
                <span class="caption text-lowercase">
                  {{ itemLabel }}
                </span>

                <span class="caption text-lowercase pl-1">
                  in
                </span>

                <span
                  :class="`caption ${styles.value}--text font-weight-bold px-1`"
                >
                  {{ totalGroups }}
                </span>

                <span class="caption text-lowercase">
                  {{ groupLabel }}
                </span>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </a>
  </v-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import format from 'src/utils/format'

const { mapGetters, mapState } = createNamespacedHelpers('tools')

export default {
  name: 'SummaryCard',
  props: {
    summaryId: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    defaultStyles: {
      background: 'primary',
      text: 'white',
      value: 'white',
    },
    configs: {
      pacing: {
        itemName: 'line item',
        groupKey: 'orderId',
        groupName: 'order',
      },
      person: {
        itemName: 'person',
        groupKey: 'access.role',
        groupName: 'role',
      },
    },
  }),
  computed: {
    ...mapGetters(['currentSummaries', 'reportClass']),
    ...mapState({
      items: (state) => state.items,
      reportId: (state) => state.reportId,
      reportName: (state) => state.reportName,
    }),
    backgroundColor() {
      const { isActive, styles } = this
      return isActive ? 'darkestblue' : styles.background
    },
    reportConfigs() {
      return this.configs[this.reportName] || {}
    },
    summary() {
      const { currentSummaries, summaryId } = this

      if (!summaryId) return {}

      return currentSummaries[summaryId]
    },
    styles() {
      const { defaultStyles, summary: { styles } } = this

      return styles || defaultStyles
    },
    didFail() {
      return this.summary.didFail
    },
    didSucceed() {
      return this.didFail === false && !this.isLoading
    },
    isActive() {
      return this.summary.isActive
    },
    isLoading() {
      return this.summary.isLoading || this.summary.isDeactivating
    },
    groupKey() {
      return this.summary.groupKey || this.reportConfigs.groupKey
    },
    groupName() {
      return this.summary.groupName || this.reportConfigs.groupName
    },
    groupLabel() {
      const { groupsLength, groupName } = this

      return groupsLength === 1 ? groupName : `${groupName}s`
    },
    groupsLength() {
      return Object.keys(this.groups).length
    },
    itemLabel() {
      const { itemsLength, itemName } = this
      const makeLabel = () => (itemsLength === 1 ? itemName || '' : `${itemName}s`)

      return (itemName && makeLabel()) || ''
    },
    itemName() {
      return this.summary.itemName || this.reportConfigs.itemName
    },
    itemsLength() {
      return this.summary.total
    },
    title() {
      return this.summary.title
    },
    total() {
      const { itemsLength } = this

      return format.number(itemsLength)
    },
    totalGroups() {
      return format.number(this.groupsLength)
    },
    groups() {
      const { groupKey, summary } = this
      const { items } = summary

      return items.reduce((original, item) => {
        const id = item[groupKey]

        if (!original[id]) {
          original[id] = []
        }

        original[id].push(items)

        return original
      }, {})
    },
  },
  watch: {
    currentSummaries: {
      deep: true,
      handler(newValue) {
        return newValue
      },
    },
  },
  methods: {
    activate() {
      if (this.isActive) {
        return this.deactivate()
      }

      return this.$store.dispatch('tools/setSavedFilter', this.summary)
    },
    deactivate() {
      this.$store.dispatch('tools/resetFilters')
    },
    showFilterInfo() {
      return this.$store.dispatch('tools/showSavedFilterInfo', this.summary)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";

.summary {
  min-width: 230px;
  max-width: 230px;
  padding-right: 9px !important;
  padding-left: 9px !important;
}

.summary-card {
  min-height: 99px;
  border-radius: 6px;

  &.loading,
  &.did-fail {
    cursor: default;
  }

  &:not(:last-child) {
    margin-right: 21px;
  }
}

.health-network-summary-card {
  min-height: 81px;
}

.summary-title {
  min-width: 183px;
  word-break: break-word;
}

.close-button {
  top: 13px;
  right: 13px;
  position: absolute;
}

.loader {
  top: 15px;
  right: 15px;
  position: absolute;
}
</style>
