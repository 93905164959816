<template>
  <div
    class="explore"
  >
    <v-col>
      <v-row>
        <v-col>
          <h3>Explore</h3>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <iframe
            :src="`${docUrl}?${querySheet}`"
            :height="sheetHeight"
            width="100%"
          />
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import mixin from '../components/mixin'

const { mapGetters: mapToolsGetters, mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'Explore',
  mixins: [mixin],
  data() {
    return {
      docUrl: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQZd-eRgQKSz0hz-9vnVUoJFrWhRHZjVhCmj1FGKMPihB9bUtSAUmUtFOWNF46p60ilayxhNKhrqj2e/pubhtml',
      querySheet: 'gid=0&amp;single=true&amp;widget=true&amp;headers=false', // gid 0 is the first sheet
      queryDoc: 'widget=true&amp;headers=false',
      sheetHeight: '670px',
    }
  },
  computed: {
    ...mapToolsState({
      reportId: (state) => state.reportId,
    }),
    ...mapToolsGetters([
      'currentViewId',
      'storedItems',
    ]),
    timeShift() {
      return this.$store.state.internal?.timeShift || 0
    },
    clients() {
      const allOrgs = this.storedItems?.organizations?.all || []
      return allOrgs.filter((org) => org.isTimesheetEnabled)
    },
    organizations() {
      return this.storedItems?.organizations?.all || []
    },
    explore() {
      return this.storedItems?.explore || []
    },
  },
}
</script>

<style scoped>
#doc-title {
  font-size: 1.5em;
  font-weight: bold;
}
</style>
